a {
  text-decoration: none;
  color: hsl(0, 0%, 45%);
}

a:hover {
  text-decoration: none;
  color: hsl(0, 0%, 0%);
}

.form-check-input{
  margin-top: -5px;
}
.form-check-input:checked {
  background-color: orange;
  border-color: orange;
  /* Add any other styles as needed */
}
.form-check-input:focus {
  outline: none !important;
  box-shadow: none !important;
}