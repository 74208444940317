body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 10px;
  margin-top: 20px;
}


.grid-cell {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  margin:  5px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: orange;
  border-color: orange;
}


.ant-checkbox-checked:hover .ant-checkbox-checked {
  /* Add your hover styles here */
  background-color: orange;
  border-color: orange;
}

.ant-message {
  margin-top: 50px; /* Adjust the value as needed */
}